module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shan's Blog","short_name":"ShansBlog","start_url":"/","display":"standalone","icon":"/Users/shan.hua/project/homesite/src/assets/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ed6e1ff3cdb9f802c6f586ddc11e43dc"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"wrapperStyle":"margin-top: 0.5rem; margin-bottom: 0.5rem;","showCaptions":true,"markdownCaptions":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
