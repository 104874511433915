import React from 'react'
import { Helmet } from 'react-helmet'
import { GlobalStyle } from './src/styles'
import Store from './src/context/common-store'

require("./src/styles/base.css")
require("prismjs/themes/prism-tomorrow.css")

export const wrapRootElement = ({ element }) => {
    return (
		<Store>
			<Helmet>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin></link>
				<link
					href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700&family=Open+Sans:wght@400;700&family=Ubuntu:wght@400;700&display=swap"
					rel="stylesheet"
				></link>
				<link
					href="https://fonts.googleapis.com/icon?family=Material+Icons&display=swap"
					rel="stylesheet"
				></link>
			</Helmet>
			<GlobalStyle />
			{element}
		</Store>
	);
};