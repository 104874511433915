import { createGlobalStyle } from 'styled-components'

/**
 * The font size is no more controlled acc.to vw to improve readability
 * esp. if a phone is being rotated over (fonts in portrait view will not be smaller than in landscape view)
 * px2vw function is only used for calculating margins and component heights
 */
export const GlobalStyle = createGlobalStyle`
    :root {
        .gatsby-resp-image-figcaption {
            text-align: center;
            padding: 0.4rem 0;
            font-size: 0.7rem;
            font-style: italic;
        }
    }
`;