import React, { useReducer, createContext } from 'react'

interface CommonState {
    error?: string | null;
}

interface CommonAction {
    type: string;
    payload?: any;
}

const initialState: CommonState = {
    error: null
}

const Reducer = (state: CommonState, action: CommonAction) => {
    switch (action.type) {
        case 'SET_ERROR': 
            return {
                ...state,
                error: String(action.payload)
            }
        case 'CLEAR_ERROR':
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const Store: React.FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)
    return (
        <CommonContext.Provider value={[state, dispatch]}>
            {children}
        </CommonContext.Provider>
    )
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const CommonContext = createContext<[CommonState, React.Dispatch<CommonAction>]>([initialState, () => {}])

export default Store