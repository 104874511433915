import styled from 'styled-components'
import { px2vw } from '@/utils'
export { GlobalStyle } from './global-style'

interface ContainerProps {
    isMain?: boolean;
}

const marginVertical = 12

export const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: ${px2vw(marginVertical)} auto;
    padding: 0 ${px2vw(64)};
    ${(props: ContainerProps) => props.isMain ? `min-height: calc(100vh - ${px2vw(marginVertical * 2)});` : ''}
    /* tablet */
    @media (min-width: 768px) {
        max-width: ${px2vw(600, 768)};
    }

    /* desktop */
    @media (min-width: 1024px) {
        max-width: 920px;
    }
`;

export const HeaderContainer = styled.header`
    position: relative;
    height: ${px2vw(108)};
    min-height: 7rem;
    padding: 0.3rem 0;
    border-bottom: 1px var(--grey) solid;
`;

export const ExcerptContainer = styled.div`
    margin: 2rem 0 3.6rem;
`

export const Title = styled.h1`
    margin: 2rem 0;
    font-family: "Ubuntu", sans-serif;
    a {
    text-decoration: none;
    }
`;

export const ExcerptTitle = styled.h2`
    margin: 0.7rem 0 1.6rem;
    font-family: "Ubuntu", sans-serif;
    a {
        color: var(--secondary-color);
        text-decoration: none;
    }
`;

export const PostContainer = styled.div`
  padding: 2rem 0 3rem;
  p {
      padding: 0.7rem 0;
  }
`;

export const Date = styled.p`
    display: block;
    font-size: small;
    font-style: italic;
`

export const FooterContainer = styled.footer`
    width: 100%;
    margin: 5rem 0 1rem;
    text-align: center;
    font-size: 0.7rem;
    color: var(--grey);
`;